.form {
  display: flex;
  flex-direction: row;
  max-width: 800px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.dayTitle {
  width: 130px;
  text-transform: capitalize;
}
.timeInput {
  width: 44%;
}
.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
