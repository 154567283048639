.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 111;
}
.modal-main {
  position: fixed;
  background: white;
  border-radius: 8px;
  /* width: 50%; */
  height: auto;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
