.lable {
  word-break: keep-all;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.marginLeft {
  margin-left: 10px;
}

.workinHoursTitle {
  margin-top: 44px;
  font-size: 17px;
  font-weight: 500;
}

.helperText {
  color: #777;
  font-size: 16px;
  font-weight: 400;
  margin: 16px 0px;
}
.checkText {
  color: #777;
  margin-left: 10px;
}

.days {
  margin: 0px 10px;
  padding: 0px 8px;
  background-color: #ededed;
  color: #777;
  border-radius: 4px;
  text-transform: capitalize;
  cursor: pointer;
}

.activeDay {
  background-color: #0062ff;
  color: white;
}
