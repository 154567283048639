.timeZoneInput {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 8px;
  border: 1px solid #ddd;
  height: 70px;
  padding: 0px 20px 0px 10px;
}
.timeZoneTitle {
  color: #777;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
}
.timezoneValue {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}
.dropdownCont {
  position: absolute;
  border-radius: 8px;
  padding: 10px 20px 0px 10px;
  top: 63px;
  left: 0px;
  right: 0px;
  background-color: white;
  z-index: 10;
  box-shadow: -1px 39px 112px -13px rgba(0, 0, 0, 0.25);
  max-height: 300px;
  overflow-y: scroll;
}

.businessHrsText {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.oneTimeZoneCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  margin: 10px 0px;
  border-radius: 4px;
  cursor: pointer;
}

.oneTimeZoneCont:hover {
  background-color: #f2f2f7;
}
