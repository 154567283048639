* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  cursor: pointer;
}

button:hover {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

button:active {
  outline: none;
  border: none;
}

button:focus {
  outline: 0;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.w-screen {
  width: 100vw;
}
.h-screen {
  height: 100vh;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover:hover {
  background-color: "#92929D";
}

.kata::-webkit-scrollbar {
  display: none;
}

.kata {
  overflow-y: scroll;
}

.trans {
  transition: all 0.5s !important;
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.h-6 {
  height: 1.5rem;
}

.h-14 {
  height: 3.5rem;
}

.w-6 {
  width: 1.5rem;
}

.w-14 {
  width: 3.5rem;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.sm\:animate-spin {
  animation: spin 1s linear infinite;
}

.md\:animate-spin {
  animation: spin 1s linear infinite;
}
.lg\:animate-spin {
  animation: spin 1s linear infinite;
}

.xl\:animate-spin {
  animation: spin 1s linear infinite;
}

.\32xl\:animate-spin {
  animation: spin 1s linear infinite;
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #f7f4ed;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #0062ffb6;
  border-radius: 100vh;
  border: 3px solid #f6f7ed;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #0062ff;
}
