.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0.9;
  transition: opacity 0.2s;
}

.tooltip::before {
  content: '';
  position: absolute;
  border-style: solid;
}

.tooltip.top::before {
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px 5px 0;
  border-color: #333 transparent transparent transparent;
}

.tooltip.bottom::before {
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #333 transparent;
}

.tooltip.left::before {
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #333;
}

.tooltip.right::before {
  top: 50%;
  left: -5px;
  transform: translateY(-50%);
  border-width: 5px 5px 5px 0;
  border-color: transparent #333 transparent transparent;
}
