.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ddd;
  height: 74px;
  padding-top: 6px;
}

.input {
  width: 100%;
  height: 56px;
  padding: 23px 16px 0 10px;
  outline: 0;
  border: transparent;
  border-radius: 4px;
  background: #fff;
  font-size: 16px;
  opacity: 0;
}

.input[type="time"]::-webkit-calendar-picker-indicator {
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.label {
  font-size: 16px;
  padding: 0 12px;
  color: #999;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 18px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
}
.clearButton {
  position: absolute;
  right: 0px;
}

.container:focus-within label {
  transform: translate(0, 0px) scale(0.75);
}

.active {
  transform: translate(0, 0px) scale(0.75);
}
.input:focus {
  opacity: 1;
}
.activeInput {
  opacity: 1;
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button,
.input::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

/* Hide the time input controls for Firefox */
.input::-moz-time-input {
  -moz-appearance: textfield;
}

/* General rule to remove input[type="time"] spinner and arrow for IE */
.input::-ms-clear,
.input::-ms-reveal {
  display: none;
}
